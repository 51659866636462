import { useEffect, useState } from "react"
import { t } from "translations"

import Button from "@mui/material/Button"
import FormControl from "@mui/material/FormControl"
import Skeleton from "@mui/material/Skeleton"
import Typography from "@mui/material/Typography"
import { styled, useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"

import { getFragment } from "data/contentData/api/fragment"
import useQueryData from "data/hooks/useQueryData"
import { setNewsletterSubscription } from "data/internal/api/newsletter"
import { setNewsletterCookie } from "data/internal/api/newsletterCookie"
import { NewsletterPopoverType } from "data/internal/interfaces/newsletterCookie"
import { getNewsletterData } from "data/mailchimp/getNewsletterType"
import { NEWSLETTER_TYPE } from "data/mailchimp/interfaces/INewsletter"
import {
    INewsletterInterestsCategories,
    INewsletterList,
    INewsletterMergeFields
} from "data/mailchimp/interfaces/INewsletterList"
import useDeviceDimensions from "lib/hooks/deviceDimensions/useDeviceDimensions"
import { rewrites } from "lib/routes"

import Icon from "Components/Icon/Icon"

import NewsletterSelect, { SelectOption } from "./NewsletterSelect"
import NewsletterTextFieldWithButton from "./NewsletterTextFieldWithButton"
import {
    IFormValues,
    clearAllFields,
    getInitialFormValues,
    getInterestRenderValue,
    getLayoutType,
    getMergefieldsRenderValue,
    getNewsletterCookieName,
    getNewsletterPalette,
    handleEmailChange,
    handleSelectChange,
    handleSignUp,
    newsletterDeviceDimensions
} from "./utils"

export default function Newsletter({
    newsletterId,
    renderFullWidth,
    bgImage
}: {
    newsletterId: NEWSLETTER_TYPE
    renderFullWidth?: boolean
    bgImage?: string
}) {
    const { data, isLoading, error } = useQueryData<INewsletterList[]>(
        "newsletterlist",
        getFragment
    )

    const newsletter: INewsletterList | undefined = getNewsletterData(
        newsletterId,
        data as INewsletterList[]
    )

    const initialFormValues = getInitialFormValues(newsletterId)

    const [form, setForm] = useState<IFormValues>(initialFormValues)
    const [emailError, setEmailError] = useState<boolean>(false)
    const [isSending, setIsSending] = useState(false)
    const [successfullySubscribed, setSuccessfullySubscribed] = useState<
        undefined | boolean
    >(undefined)

    // set a cookie that expires in 3 weeks if user subscribes to the newsletter
    useEffect(() => {
        const isPopoverNewsletter = getNewsletterCookieName(newsletterId)
        const cookieExpireDate = 21 * 7 * 24 * 60 * 60 // 3 weeks
        if (successfullySubscribed === true && isPopoverNewsletter !== false)
            setNewsletterCookie(
                newsletterId as NewsletterPopoverType,
                cookieExpireDate
            )
    }, [successfullySubscribed, newsletterId])

    const theme = useTheme()
    const newsletterPalette = getNewsletterPalette(newsletterId, theme)

    const isMdUp = useMediaQuery(theme.breakpoints.up("md"))
    const showAsFullWidth = getLayoutType(renderFullWidth, isMdUp)

    const skeletonValues = newsletterDeviceDimensions[useDeviceDimensions()]

    if (error) return null

    if (isLoading)
        return (
            <Skeleton
                variant="rectangular"
                height={skeletonValues.height}
                width={"100%"}
            />
        )

    if (!newsletter) return null

    return (
        <StyledWrapperFullwidth
            palette={newsletterPalette}
            isfullwidth={showAsFullWidth.toString()}
            bgImage={bgImage}>
            <StyledDiv>
                <StyledHeading
                    variant="h2"
                    color="inherit">
                    {newsletter.title}
                </StyledHeading>
                {newsletter.text && (
                    <Typography
                        variant="body2"
                        color="inherit">
                        {newsletter.text}
                    </Typography>
                )}

                <StyledButton
                    component="a"
                    href={rewrites["/newsletter"]}
                    endIcon={<Icon name="arrowForward" />}
                    variant="text">
                    {t.newsletter.seeAllNewsletters}
                </StyledButton>
            </StyledDiv>

            <StyledForm
                component="form"
                id="newsletter-form"
                fullWidth
                onSubmit={e =>
                    handleSignUp(
                        e,
                        emailError,
                        form,
                        setIsSending,
                        setSuccessfullySubscribed,
                        setNewsletterSubscription
                    )
                }>
                {successfullySubscribed !== undefined ? (
                    <StyledSuccessText
                        variant={"h3"}
                        sucsessfullysubscribed={successfullySubscribed.toString()}
                        component="p">
                        {successfullySubscribed
                            ? `${t.newsletter.success}`
                            : `${t.newsletter.error}`}

                        <StyledResetButton
                            onClick={() =>
                                clearAllFields(
                                    setForm,
                                    initialFormValues,
                                    setEmailError,
                                    setSuccessfullySubscribed
                                )
                            }
                            fullWidth
                            variant="contained">
                            {t.newsletter.resetForm}
                        </StyledResetButton>
                    </StyledSuccessText>
                ) : (
                    <>
                        {newsletter.categories &&
                            newsletter.categories.map(
                                (item: INewsletterInterestsCategories) => {
                                    return (
                                        <NewsletterSelect
                                            type="interests"
                                            name={item.title}
                                            required={item.required}
                                            label={item.title}
                                            options={
                                                item.interests.map(
                                                    (
                                                        interest: SelectOption
                                                    ) => {
                                                        return {
                                                            id: interest.id,
                                                            name: interest.name
                                                        }
                                                    }
                                                ) as SelectOption[]
                                            }
                                            selectedValue={getInterestRenderValue(
                                                item.title,
                                                form
                                            )}
                                            handleChange={event =>
                                                handleSelectChange(
                                                    event,
                                                    "interests",
                                                    form,
                                                    setForm
                                                )
                                            }
                                            key={item.title}
                                            palette={newsletterPalette}
                                        />
                                    )
                                }
                            )}

                        {newsletter.merge_fields &&
                            newsletter.merge_fields.map(
                                (item: INewsletterMergeFields) => {
                                    return (
                                        <NewsletterSelect
                                            type="mergefields"
                                            name={item.name}
                                            required={item.required}
                                            label={item.name}
                                            options={item.options}
                                            selectedValue={getMergefieldsRenderValue(
                                                item.tag,
                                                form
                                            )}
                                            handleChange={event =>
                                                handleSelectChange(
                                                    event,
                                                    "mergefields",
                                                    form,
                                                    setForm,
                                                    item.tag
                                                )
                                            }
                                            key={item.tag}
                                            palette={newsletterPalette}
                                        />
                                    )
                                }
                            )}

                        <NewsletterTextFieldWithButton
                            onInvalid={e => {
                                e.preventDefault()
                                setEmailError(true)
                            }}
                            emailError={emailError}
                            fullWidth={showAsFullWidth}
                            palette={newsletterPalette}
                            email={form.email}
                            handleSetEmail={input =>
                                handleEmailChange(
                                    input,
                                    form,
                                    setForm,
                                    setEmailError
                                )
                            }
                            isSending={isSending}
                        />
                    </>
                )}
            </StyledForm>
        </StyledWrapperFullwidth>
    )
}

const StyledWrapperFullwidth = styled("div")<{
    palette: any
    isfullwidth: string
    bgImage: string | undefined
}>(({ theme, palette, isfullwidth, bgImage }) => ({
    ...(bgImage === undefined
        ? {
              backgroundColor: `${palette.main}`
          }
        : {
              backgroundImage: `url(${bgImage})`,
              backgroundSize: "cover"
          }),
    display: isfullwidth === "true" ? "grid" : "flex",
    flexDirection: "column",
    gridTemplateColumns: isfullwidth === "true" ? "1fr 1fr" : "unset",
    gap: isfullwidth === "true" ? theme.spacing(4) : theme.spacing(2),
    overflow: "hidden",
    color: `${palette.contrastText}`,
    padding: isfullwidth === "true" ? theme.spacing(3, 4) : theme.spacing(2)
}))

const StyledDiv = styled("div")({
    display: "flex",
    flexDirection: "column"
})

const StyledHeading = styled(Typography)(({ theme }) => ({
    "&.MuiTypography-h2": {
        fontSize: "2rem"
    },
    marginBottom: theme.spacing(0.5)
}))

const StyledButton = styled(Button)<{ component: string }>(({ theme }) => ({
    marginLeft: theme.spacing(-1),
    marginRight: "auto",
    marginTop: "auto",
    marginBottom: theme.spacing(-1),
    "&:hover": {
        backgroundColor: "unset",
        textDecoration: "underline"
    }
}))

const StyledForm = styled(FormControl)<{ component: string }>(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2.5),
    justifyContent: "center"
}))

const StyledSuccessText = styled(Typography)<{
    component: string
    sucsessfullysubscribed: string
}>(({ sucsessfullysubscribed, theme }) => ({
    textAlign: "center",
    color:
        sucsessfullysubscribed === "true"
            ? theme.palette.text.primary
            : theme.palette.error.main
}))

const StyledResetButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
        backgroundColor: theme.palette.primary.light
    },
    "&:focus": {
        outlineOffset: "-2px"
    }
}))
